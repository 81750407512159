import { Button, Checkbox, Label } from "flowbite-react";
import { useState } from "react";

export default function AdvancedParamsComponent({ setAdvancedParams }) {
    const [songDifficultyAdvanced, setSongDifficultyAdvanced] = useState(false);
    const [songDifficultyMin, setSongDifficultyMin] = useState(0);
    const [songDifficultyMax, setSongDifficultyMax] = useState(100);

    const handleSubmit = (e) => {
        e.preventDefault();

        const target = e.target;

        console.log(target.songDifficultyAdvanced.checked)

        const newAdvancedParams = {
            "animeStatusPTW": target.animeStatusPTW.checked ? true : false,
            "animeStatusWatching": target.animeStatusWatching.checked ? true : false,
            "animeStatusCompleted": target.animeStatusCompleted.checked ? true : false,
            "animeStatusOnHold": target.animeStatusOnHold.checked ? true : false,
            "animeStatusDropped": target.animeStatusDropped.checked ? true : false,
            "songTypeOP": target.songTypeOP.checked ? true : false,
            "songTypeED": target.songTypeED.checked ? true : false,
            "songTypeInsert": target.songTypeInsert.checked ? true : false,
            "songDifficultyAdvanced": target.songDifficultyAdvanced.checked ? true : false,
            "songDifficultyEasy": !target.songDifficultyAdvanced.checked ? target.songDifficultyEasy.checked ? true : false : false,
            "songDifficultyMedium": !target.songDifficultyAdvanced.checked ? target.songDifficultyMedium.checked ? true : false : false,
            "songDifficultyHard": !target.songDifficultyAdvanced.checked ? target.songDifficultyHard.checked ? true : false : false,
            "songDifficultyMin": target.songDifficultyAdvanced.checked && parseInt(target.songDifficultyMin.value),
            "songDifficultyMax": target.songDifficultyAdvanced.checked && parseInt(target.songDifficultyMax.value),
            "animeTypeTV": target.animeTypeTV.checked ? true : false,
            "animeTypeMovie": target.animeTypeMovie.checked ? true : false,
            "animeTypeOVA": target.animeTypeOVA.checked ? true : false,
            "animeTypeONA": target.animeTypeONA.checked ? true : false,
            "animeTypeSpecial": target.animeTypeSpecial.checked ? true : false,
            "songCategoryStandard": target.songCategoryStandard.checked ? true : false,
            "songCategoryInstrumental": target.songCategoryInstrumental.checked ? true : false,
            "songCategoryChanting": target.songCategoryChanting.checked ? true : false,
            "songCategoryCharacter": target.songCategoryCharacter.checked ? true : false,
            "otherRebroadcast": parseInt(target.otherRebroadcast.value),
            "otherDub": parseInt(target.otherDub.value),
        }

        setAdvancedParams(newAdvancedParams);
    }

    return (
        <>
            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white text-center">
                Advanced Search
            </h5>
            <form className="flex max-w-md flex-col gap-2 h-full overflow-y" onSubmit={handleSubmit}>
                <p className="text-lg text-gray-900 dark:text-white text-center">Anime Status</p>
                <div className="flex items-center gap-2">
                    <Checkbox id="animeStatusPTW" />
                    <Label htmlFor="animeStatusPTW">Plan to Watch</Label>
                </div>
                <div className="flex items-center gap-2">
                    <Checkbox id="animeStatusWatching" defaultChecked />
                    <Label htmlFor="animeStatusWatching">Watching</Label>
                </div>
                <div className="flex items-center gap-2">
                    <Checkbox id="animeStatusCompleted" defaultChecked />
                    <Label htmlFor="animeStatusCompleted">Completed</Label>
                </div>
                <div className="flex items-center gap-2">
                    <Checkbox id="animeStatusOnHold" />
                    <Label htmlFor="animeStatusOnHold">On Hold</Label>
                </div>
                <div className="flex items-center gap-2">
                    <Checkbox id="animeStatusDropped" />
                    <Label htmlFor="animeStatusDropped">Dropped</Label>
                </div>

                <p className="text-lg text-gray-900 dark:text-white text-center">Song Type</p>
                <div className="flex items-center gap-2">
                    <Checkbox id="songTypeOP" defaultChecked />
                    <Label htmlFor="songTypeOP">OP</Label>
                </div>
                <div className="flex items-center gap-2">
                    <Checkbox id="songTypeED" defaultChecked />
                    <Label htmlFor="songTypeED">ED</Label>
                </div>
                <div className="flex items-center gap-2">
                    <Checkbox id="songTypeInsert" defaultChecked />
                    <Label htmlFor="songTypeInsert">INSERT</Label>
                </div>

                <p className="text-lg text-gray-900 dark:text-white text-center">Song Difficulty</p>
                <div className="flex justify-center">
                    <label class="inline-flex items-center cursor-pointer">
                        <span class="me-3 text-sm font-medium text-gray-900 dark:text-gray-300">Default</span>
                        <input type="checkbox" name="songDifficultyAdvanced" value={songDifficultyAdvanced} onChange={() => setSongDifficultyAdvanced(!songDifficultyAdvanced)} class="sr-only peer" />
                        <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Advanced</span>
                    </label>
                </div>
                {
                    songDifficultyAdvanced ? (
                        <>
                            <label htmlFor="songDifficultyMin" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Min {songDifficultyMin}</label>
                            <input id="songDifficultyMin" name="songDifficultyMin" type="range" min={0} max={songDifficultyMax} value={songDifficultyMin} onChange={e => setSongDifficultyMin(e.target.value)} class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700" />

                            <label htmlFor="songDifficultyMax" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Max {songDifficultyMax}</label>
                            <input id="songDifficultyMax" name="songDifficultyMax" type="range" max={100} min={songDifficultyMin} value={songDifficultyMax} onChange={e => setSongDifficultyMax(e.target.value)} class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700" />
                        </>
                    ) : (
                        <>
                            <div className="flex items-center gap-2">
                                <Checkbox id="songDifficultyEasy" defaultChecked />
                                <Label htmlFor="songDifficultyEasy">Easy</Label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Checkbox id="songDifficultyMedium" defaultChecked />
                                <Label htmlFor="songDifficultyMedium">Medium</Label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Checkbox id="songDifficultyHard" defaultChecked />
                                <Label htmlFor="songDifficultyHard">Hard</Label>
                            </div>
                        </>
                    )
                }

                <p className="text-lg text-gray-900 dark:text-white text-center">Anime Type</p>
                <div className="flex items-center gap-2">
                    <Checkbox id="animeTypeTV" defaultChecked />
                    <Label htmlFor="animeTypeTV">TV</Label>
                </div>
                <div className="flex items-center gap-2">
                    <Checkbox id="animeTypeMovie" defaultChecked />
                    <Label htmlFor="animeTypeMovie">Movie</Label>
                </div>
                <div className="flex items-center gap-2">
                    <Checkbox id="animeTypeOVA" defaultChecked />
                    <Label htmlFor="animeTypeOVA">OVA</Label>
                </div>
                <div className="flex items-center gap-2">
                    <Checkbox id="animeTypeONA" defaultChecked />
                    <Label htmlFor="animeTypeONA">ONA</Label>
                </div>
                <div className="flex items-center gap-2">
                    <Checkbox id="animeTypeSpecial" defaultChecked />
                    <Label htmlFor="animeTypeSpecial">Special</Label>
                </div>

                <p className="text-lg text-gray-900 dark:text-white text-center">Song Category</p>
                <div className="flex items-center gap-2">
                    <Checkbox id="songCategoryStandard" defaultChecked />
                    <Label htmlFor="songCategoryStandard">Standard</Label>
                </div>
                <div className="flex items-center gap-2">
                    <Checkbox id="songCategoryInstrumental" defaultChecked />
                    <Label htmlFor="songCategoryInstrumental">Instrumental</Label>
                </div>
                <div className="flex items-center gap-2">
                    <Checkbox id="songCategoryChanting" defaultChecked />
                    <Label htmlFor="songCategoryChanting">Chanting</Label>
                </div>
                <div className="flex items-center gap-2">
                    <Checkbox id="songCategoryCharacter" defaultChecked />
                    <Label htmlFor="songCategoryCharacter">Character</Label>
                </div>

                <p className="text-lg text-gray-900 dark:text-white text-center">Other</p>
                <div className="relative mb-6">
                    <label htmlFor="otherRebroadcast" className="sr-only">Rebroadcast</label>
                    <input id="otherRebroadcast" name="otherRebroadcast" type="range" defaultValue="2" min="1" max="3" className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700" />
                    <span className="text-sm text-gray-500 dark:text-gray-400 absolute start-0 -bottom-6">None</span>
                    <span className="text-sm text-gray-500 dark:text-gray-400 absolute start-1/2 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6">Rebroadcast</span>
                    <span className="text-sm text-gray-500 dark:text-gray-400 absolute end-0 -bottom-6">Only</span>
                </div>
                <div className="relative mb-6">
                    <label htmlFor="otherDub" className="sr-only">Dub</label>
                    <input id="otherDub" name="otherDub" type="range" defaultValue="2" min="1" max="3" className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700" />
                    <span className="text-sm text-gray-500 dark:text-gray-400 absolute start-0 -bottom-6">None</span>
                    <span className="text-sm text-gray-500 dark:text-gray-400 absolute start-1/2 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6">Dub</span>
                    <span className="text-sm text-gray-500 dark:text-gray-400 absolute end-0 -bottom-6">Only</span>
                </div>
                {/* <div className="flex items-center gap-2">
                    <Checkbox id="otherRebroadcast" defaultChecked />
                    <Label htmlFor="otherRebroadcast">Rebroadcast</Label>
                </div>
                <div className="flex items-center gap-2">
                    <Checkbox id="otherDub" defaultChecked />
                    <Label htmlFor="otherDub">Dub</Label>
                </div> */}
                <Button type="submit">Submit</Button>
            </form>
        </>
    );
}
