import { useParams } from "react-router-dom";
import DefaultLayout from "../templates/DefaultLayout"
import { Card } from "flowbite-react";
import { useEffect, useState } from "react";
import AnimeListComponent from "../components/AnimeListComponent";
import { Player } from "../components/Player";

export default function AnimeList() {
    const { list, name } = useParams();
    const [pageState, setPageState] = useState('loading');

    const [animeList, setAnimesList] = useState(null);
    const [playlist, setPlaylist] = useState(null);
    const [currentSongId, setCurrentSongId] = useState(null);

    function SortList(data) {
        const order = ['Opening', 'Ending', 'Insert']

        return data.sort((aTitle, bTitle) => {
            aTitle.songs = aTitle.songs.sort((aSTitle, bSTitle) => {
                const aSTitleTemp = aSTitle.songType.split(" ");
                const bSTitleTemp = bSTitle.songType.split(" ");

                return order.indexOf(aSTitleTemp[0]) - order.indexOf(bSTitleTemp[0]) || aSTitleTemp === 'Song' ? bSTitleTemp[1] : bSTitleTemp[1] === 'Song' ? aSTitleTemp[1] : aSTitleTemp[1] - bSTitleTemp[1];
            })

            return aTitle.animeJPName.localeCompare(bTitle.animeJPName)
        })
    }

    function GenPlaylist(data) {
        const pl = data.map(anime => anime.songs.map(song => ({
            "annSongId": song.annSongId,
            "animeJPName": anime.animeJPName,
            "songName": song.songName,
            "songArtist": song.songArtist,
            "audio": song.audio
        }))).flat()

        setPlaylist(pl);
    }

    useEffect(() => {
        switch (list) {
            case "mal":
                fetch('https://api.otakudb.ru/api/u/mal/' + name)
                    .then((response) => response.json())
                    .then((data) => {
                        const sortedList = SortList(data);
                        GenPlaylist(sortedList)
                        setAnimesList(sortedList);
                        setPageState('loaded');
                    }).catch(error => {
                        console.log('ERROR', error)
                        setPageState('error');
                    });
                break;
            case "anilist":
                fetch('https://api.otakudb.ru/api/u/anilist/' + name)
                    .then((response) => response.json())
                    .then((data) => {
                        const sortedList = SortList(data)
                        GenPlaylist(sortedList)
                        setAnimesList(sortedList);
                        setPageState('loaded');
                    }).catch(error => {
                        console.log('ERROR', error)
                        setPageState('error');
                    });
                break;

            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (pageState === 'loading') {
        return (
            <DefaultLayout header={`Loading...`}>
                <Card>
                    <h5 className="text-2xl text-center font-bold tracking-tight text-gray-900 dark:text-white">
                        Loading
                    </h5>
                </Card>
            </DefaultLayout>
        );
    } else if (pageState === 'error') {
        return (
            <DefaultLayout header={`Error`}>
                <Card>
                    <h5 className="text-2xl text-center font-bold tracking-tight text-gray-900 dark:text-white">
                        Error
                    </h5>
                </Card>
            </DefaultLayout>
        );
    } else if (pageState === 'loaded') {
        return (
            <DefaultLayout header={`${list === 'mal' ? 'MyAnimeList' : 'Anilist'} - ${name}`} playerMargin={true}>
                <AnimeListComponent setCurrentSongId={setCurrentSongId} animeList={animeList} GenPlaylist={GenPlaylist} />
                <Player currentSongId={currentSongId} setCurrentSongId={setCurrentSongId} playlist={playlist} />
            </DefaultLayout>
        );
    }
}
