import { Button, Card, Label, TextInput } from "flowbite-react";
import DefaultLayout from "../templates/DefaultLayout"
import { Link, useNavigate } from "react-router-dom";
// import News from '../data/news.json'

export default function Main() {
  const navigate = useNavigate();

  return (
    <DefaultLayout header={'Home'}>
      <section class="bg-center bg-no-repeat bg-[url('https://desu.shikimori.one/system/user_images/original/29386/498504.jpg')] bg-gray-400 dark:bg-gray-700 bg-blend-multiply mb-2 rounded-lg shadow-md">
        <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16">
          <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl dark:text-white">
            OtakuDB.Songs
          </h1>
          <p class="mb-8 text-lg font-normal text-gray-200 lg:text-xl sm:px-16 lg:px-24 dark:text-gray-400">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <div class="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0">
            <Link href={'/search'} class="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900">
              Search Songs
              <svg class="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
              </svg>
            </Link>
            {/* <Link href="#" class="py-3 px-5 sm:ms-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-70">
              Learn more
            </Link> */}
          </div>
        </div>
      </section>

      <div class="grid grid-cols-1 lg:grid-cols-4 gap-2 pb-2">
        <div className="col-span-1 lg:col-span-2">
          <Card>
            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              MyAnimeList
            </h5>
            <form className="flex flex-col gap-4" onSubmit={(e) => navigate(`/u/mal/${e.target.username.value}`)}>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="usernameMal" value="Username" />
                </div>
                <TextInput id="usernameMal" type="text" name="username" />
              </div>
              <Button type="submit">Submit</Button>
            </form>
          </Card>
        </div>
        <div className="col-span-1 lg:col-span-2">
          <Card>
            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Anilist
            </h5>
            <form className="flex flex-col gap-4" onSubmit={(e) => navigate(`/u/anilist/${e.target.username.value}`)}>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="usernameAnilist" value="Username" />
                </div>
                <TextInput id="usernameAnilist" type="text" name="username" />
              </div>
              <Button type="submit">Submit</Button>
            </form>
          </Card>
        </div>
      </div>

      {/* <Card>
        <ol class="items-center sm:flex">
          {
            News.map((post, key) => key < 4 ? (
              <li class="relative mb-6 sm:mb-0  w-full">
                <div class="flex items-center">
                  <div class="z-10 flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full ring-0 ring-white dark:bg-blue-900 sm:ring-8 dark:ring-gray-900 shrink-0">
                    <svg class="w-2.5 h-2.5 text-blue-800 dark:text-blue-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                    </svg>
                  </div>
                  <div class="hidden sm:flex w-full bg-gray-200 h-0.5 dark:bg-gray-700"></div>
                </div>
                <div class="mt-3 sm:pe-8">
                  <h3 class="text-lg font-semibold text-gray-900 dark:text-white">{post.title}</h3>
                  <time class="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">Posted by {post.author}</time>
                  <p class="text-base font-normal text-gray-500 dark:text-gray-400">{post.description}</p>
                  <a href="#" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-100 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-700"><svg class="w-3.5 h-3.5 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z" />
                    <path d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                  </svg> Download ZIP</a>
                </div>
              </li>
            ) : (''))
          }
        </ol>
      </Card> */}
    </DefaultLayout>
  );
}
