import { Button, Checkbox, HR, Pagination, Popover, Table } from "flowbite-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { customTableTheme } from "../templates/customThemes";

function SearchWithFilter(search, animeList, advancedParams) {
    const tempSearch = search.toLowerCase();

    let newAnimeList = [];

    animeList.forEach(anime => {
        let tempAnime = { ...anime };

        let searchByAnime = false;

        if (
            !(
                (
                    (advancedParams.animeStatusPTW && tempAnime.animeStatus === 'ptv') ||
                    (advancedParams.animeStatusWatching && tempAnime.animeStatus === 'watching') ||
                    (advancedParams.animeStatusCompleted && tempAnime.animeStatus === 'completed') ||
                    (advancedParams.animeStatusOnHold && tempAnime.animeStatus === 'onhold') ||
                    (advancedParams.animeStatusDropped && tempAnime.animeStatus === 'dropped')
                )
                &&
                (
                    (advancedParams.animeTypeTV && tempAnime.animeType === 'TV') ||
                    (advancedParams.animeTypeMovie && tempAnime.animeType === 'Movie') ||
                    (advancedParams.animeTypeOVA && tempAnime.animeType === 'OVA') ||
                    (advancedParams.animeTypeONA && tempAnime.animeType === 'ONA') ||
                    (advancedParams.animeTypeSpecial && tempAnime.animeType === 'Special')
                )
            )
        ) return;

        if (
            (tempAnime.animeJPName.toLowerCase()).indexOf(tempSearch) !== -1 ||
            (tempAnime.animeENName.toLowerCase()).indexOf(tempSearch) !== -1 ||
            (tempAnime.animeAltName && tempAnime.animeAltName.some(altName => (altName.toLowerCase()).indexOf(tempSearch) !== -1))
        ) searchByAnime = true;

        let tempSongs = [];

        tempAnime.songs.forEach(song => {
            let tempSong = { ...song }

            if (
                (advancedParams.otherRebroadcast === 1 && tempSong.isRebroadcast === 1) ||
                (advancedParams.otherRebroadcast === 3 && tempSong.isRebroadcast === 0) ||
                (advancedParams.otherDub === 1 && tempSong.isDub === 1) ||
                (advancedParams.otherDub === 3 && tempSong.isDub === 0)
            ) return

            if (
                (!searchByAnime)
                &&
                !(
                    (tempSong.songArtist.toLowerCase()).indexOf(tempSearch) !== -1 ||
                    (tempSong.songName.toLowerCase()).indexOf(tempSearch) !== -1 ||
                    (tempSong.artists && tempSong.artists.some(artist => artist.names.some(name => (name.toLowerCase()).indexOf(tempSearch) !== -1))) ||
                    (tempSong.composers && tempSong.composers.some(composer => composer.names.some(name => (name.toLowerCase()).indexOf(tempSearch) !== -1))) ||
                    (tempSong.arrangers && tempSong.arrangers.some(arranger => arranger.names.some(name => (name.toLowerCase()).indexOf(tempSearch) !== -1)))
                )
            ) return;

            if (
                !(
                    (
                        (advancedParams.songTypeOP && tempSong.songType.split(" ")[0] === 'Opening') ||
                        (advancedParams.songTypeED && tempSong.songType.split(" ")[0] === 'Ending') ||
                        (advancedParams.songTypeInsert && tempSong.songType.split(" ")[0] === 'Insert')
                    )
                    &&
                    (
                        (
                            (!advancedParams.songDifficultyAdvanced)
                            &&
                            (
                                (advancedParams.songDifficultyEasy && (tempSong.songDifficulty >= 60 && tempSong.songDifficulty <= 100)) ||
                                (advancedParams.songDifficultyMedium && (tempSong.songDifficulty >= 26 && tempSong.songDifficulty <= 59)) ||
                                (advancedParams.songDifficultyHard && (tempSong.songDifficulty >= 0 && tempSong.songDifficulty <= 25))
                            )
                        ) || (
                            (advancedParams.songDifficultyAdvanced)
                            &&
                            (tempSong.songDifficulty >= advancedParams.songDifficultyMin && tempSong.songDifficulty <= advancedParams.songDifficultyMax)
                        )
                    )
                    &&
                    (
                        (advancedParams.songCategoryStandard && tempSong.songCategory === 'Standard') ||
                        (advancedParams.songCategoryInstrumental && tempSong.songCategory === 'Instrumental') ||
                        (advancedParams.songCategoryChanting && tempSong.songCategory === 'Chanting') ||
                        (advancedParams.songCategoryCharacter && tempSong.songCategory === 'Character')
                    )
                )
            ) return;

            tempSongs.push(tempSong);
        });

        if (tempSongs.length > 0) {
            tempAnime.songs = tempSongs;

            newAnimeList.push(tempAnime);
        }
    });

    return newAnimeList;
}

export default function AnimeListWithParamsComponent({ setCurrentSongId, advancedParams, animeList, GenPlaylist }) {
    const [animeListWithParams, setAnimeListWithParams] = useState(null);
    const [splicedAnimeList, setSplicedAnimeList] = useState(null);
    const [page, setPage] = useState(1);
    const [maxPages, setMaxPages] = useState(0);
    const [search, setSearch] = useState("");

    function handleChangePage(page) {
        setPage(page)
    }

    useEffect(() => {
        setPage(1);

        const newAnimeList = SearchWithFilter(search, animeList, advancedParams)

        setMaxPages(Math.floor(newAnimeList.length / 50) + 1);

        if (newAnimeList.length > 0) {
            setAnimeListWithParams(newAnimeList);
        } else {
            setAnimeListWithParams(null);
        }
    }, [animeList, advancedParams, search])

    useEffect(() => {
        if (animeListWithParams !== null) {
            const tempAnimeListWithParams = [...animeListWithParams];

            const tempPage = (page - 1) * 50

            const newSplicedAnimeList = tempAnimeListWithParams.splice(tempPage, 50);

            setSplicedAnimeList(newSplicedAnimeList);
            GenPlaylist(newSplicedAnimeList)
        } else {
            setSplicedAnimeList(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animeListWithParams, page])

    function handleSearchSubmit(e) {
        e.preventDefault();

        const search = e.target.search.value;

        setSearch(search)
    }

    return (
        <>
            <form className="flex w-full p-2" onSubmit={handleSearchSubmit}>
                {/* <TextInput theme={customTextInputTheme_1} className="rounded-none border-none ring-none w-full" name="search" type="text" placeholder="" required /> */}
                <input type="text" name="search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-s-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                <Button type="submit" className="rounded-s-none">Search</Button>
            </form>
            {
                (splicedAnimeList && maxPages > 1) && (
                    <div className="flex overflow-x-auto justify-center pb-2">
                        <Pagination
                            className="table"
                            currentPage={page}
                            totalPages={maxPages}
                            onPageChange={handleChangePage}
                            previousLabel=""
                            nextLabel=""
                            showIcons />
                    </div>
                )
            }
            <div className="w-full overflow-x-scroll sm:overflow-x-hidden p-3">
                {
                    splicedAnimeList ? (
                        splicedAnimeList.map((anime, k) => (
                            <Table theme={customTableTheme} className="table-fixed w-[800px] sm:w-full" key={k} hoverable>
                                <caption className="pb-2 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                                    <div className="w-full flex justify-between">
                                        <div>
                                            <p className="inline pe-2">{anime.animeJPName}</p>
                                            <span class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-1 rounded dark:bg-gray-700 dark:text-gray-300">
                                                {anime.animeType}
                                            </span>

                                            <span class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-1 rounded dark:bg-gray-700 dark:text-gray-300">
                                                {
                                                    anime.animeStatus === 'ptv' ? 'Plan to Watch' :
                                                        anime.animeStatus === 'watching' ? 'Watching' :
                                                            anime.animeStatus === 'completed' ? 'Completed' :
                                                                anime.animeStatus === 'onhold' ? 'On Hold' :
                                                                    anime.animeStatus === 'dropped' ? 'Dropped' : 'Null'
                                                }
                                            </span>

                                            <Popover
                                                aria-labelledby="default-popover"
                                                theme={{
                                                    "base": "absolute z-20 inline-block w-max max-w-[100vw] bg-white outline-none border border-gray-200 rounded-lg shadow-lg dark:border-gray-600 dark:bg-gray-800 opacity-90",
                                                    "content": "z-10 overflow-hidden rounded-[7px]",
                                                    "arrow": {
                                                        "base": "absolute h-2 w-2 z-0 rotate-45 mix-blend-lighten bg-white border border-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:mix-blend-color",
                                                        "placement": "-4px"
                                                    }
                                                }}
                                                content={
                                                    <div className="text-sm text-gray-500 dark:text-gray-400">
                                                        <div className="border-b border-gray-200 bg-gray-100 px-3 py-2 dark:border-gray-600 dark:bg-gray-700">
                                                            <h3 id="default-popover" className="font-semibold text-gray-900 dark:text-white">Anime Info</h3>
                                                        </div>
                                                        <div className="px-6 py-2 bg-white shadow dark:bg-gray-800 text-center text-base">
                                                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                                                                <div>
                                                                    <p>Anime Id: {anime.songs[0].annId}</p>
                                                                    <p>Type: {anime.animeType}</p>
                                                                    <p>Season: {anime.animeVintage}</p>
                                                                </div>
                                                                <div>
                                                                    <p><a className="font-medium text-cyan-600 hover:underline dark:text-cyan-500" href={`https://myanimelist.net/anime/${anime.mal}`}>MyAnimeList</a></p>
                                                                    <p><a className="font-medium text-cyan-600 hover:underline dark:text-cyan-500" href={`https://anilist.co/anime/${anime.anilist}`}>Anilist</a></p>
                                                                    <p><a className="font-medium text-cyan-600 hover:underline dark:text-cyan-500" href={`https://kitsu.app/anime/${anime.kitsu}`}>Kitsu</a></p>
                                                                    <p><a className="font-medium text-cyan-600 hover:underline dark:text-cyan-500" href={`https://anidb.net/anime/${anime.anidb}`}>AniDB</a></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            >
                                                <Link className="font-medium text-sm text-cyan-600 hover:underline dark:text-cyan-500">
                                                    Info
                                                </Link>
                                            </Popover>

                                            <p className="text-sm text-gray-500">{anime.animeENName}</p>
                                        </div>
                                        <div className="text-end">
                                            <p className="flex text-base text-gray-500">
                                                <span class="bg-gray-100 text-gray-800 text-sm font-medium ms-2 px-2.5 py-1 rounded dark:bg-gray-700 dark:text-gray-300">
                                                    OP: {
                                                        anime.songs.filter(song => song.songType.split(" ")[0] === "Opening").length
                                                    }
                                                </span>
                                                <span class="bg-gray-100 text-gray-800 text-sm font-medium ms-2 px-2.5 py-1 rounded dark:bg-gray-700 dark:text-gray-300">
                                                    ED: {
                                                        anime.songs.filter(song => song.songType.split(" ")[0] === "Ending").length
                                                    }
                                                </span>
                                                <span class="bg-gray-100 text-gray-800 text-sm font-medium ms-2 px-2.5 py-1 rounded dark:bg-gray-700 dark:text-gray-300">
                                                    INSERT: {
                                                        anime.songs.filter(song => song.songType.split(" ")[0] === "Insert").length
                                                    }
                                                </span>
                                            </p>
                                            {/* <span class="bg-gray-100 text-gray-800 text-xs font-medium px-2.5 py-1 rounded dark:bg-gray-700 dark:text-gray-300">
                                                {anime.animeStatus}
                                            </span> */}
                                            {/* <p className="text-sm text-gray-500">{anime.animeStatus}</p> */}
                                        </div>
                                    </div>
                                </caption>
                                <Table.Head className="w-full">
                                    <Table.HeadCell className="w-6"></Table.HeadCell>
                                    <Table.HeadCell className="w-28">Type</Table.HeadCell>
                                    <Table.HeadCell className="w-500">Name</Table.HeadCell>
                                    <Table.HeadCell className="w-500">Artist</Table.HeadCell>
                                    <Table.HeadCell className="w-6"></Table.HeadCell>
                                    <Table.HeadCell className="w-8"></Table.HeadCell>
                                    <Table.HeadCell className="w-8"></Table.HeadCell>
                                    <Table.HeadCell className="w-8"></Table.HeadCell>
                                </Table.Head>
                                <Table.Body className="divide-y">
                                    {
                                        anime.songs && anime.songs.map((song, j) => (
                                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={j}>
                                                <Table.Cell>
                                                    <Checkbox />
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {song.songType} {song.isRebroadcast === 1 ? '(R)' : song.isDub === 1 ? '(D)' : ''}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {song.songName}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {song.songArtist}
                                                </Table.Cell>
                                                <Table.Cell className="text-center">
                                                    {song.songDifficulty}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {/* <Link className="font-medium text-cyan-600 hover:underline dark:text-cyan-500">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                                        </svg>
                                                    </Link> */}

                                                    <Popover
                                                        aria-labelledby="default-popover"
                                                        theme={{
                                                            "base": "absolute z-20 inline-block w-max max-w-[100vw] bg-white outline-none border border-gray-200 rounded-lg shadow-lg dark:border-gray-600 dark:bg-gray-800 opacity-90",
                                                            "content": "z-10 overflow-hidden rounded-[7px]",
                                                            "arrow": {
                                                                "base": "absolute h-2 w-2 z-0 rotate-45 mix-blend-lighten bg-white border border-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:mix-blend-color",
                                                                "placement": "-4px"
                                                            }
                                                        }}
                                                        content={
                                                            <div className="text-sm text-gray-500 dark:text-gray-400">
                                                                <div className="border-b border-gray-200 bg-gray-100 px-3 py-2 dark:border-gray-600 dark:bg-gray-700">
                                                                    <h3 id="default-popover" className="font-semibold text-gray-900 dark:text-white">Song Info</h3>
                                                                </div>
                                                                <div className="px-6 py-2 bg-white shadow dark:bg-gray-800 text-center text-base">
                                                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 pb-2 sm:pb-4">
                                                                        <div>
                                                                            <p>Song Id: {song.annSongId}</p>
                                                                            <p>AMQ Song Id: {song.amqSongId}</p>
                                                                        </div>
                                                                        <div>
                                                                            <p>Category: {song.songCategory}</p>
                                                                            <p>Difficulty: {song.songDifficulty}</p>
                                                                            <p>Length: {song.songLength}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
                                                                        <div className="w-40">
                                                                            <p className="bg-gray-100 dark:bg-gray-700">Artists</p>
                                                                            {
                                                                                song.artists ? (
                                                                                    song.artists.map(artist => (
                                                                                        artist.names.map((name, j) => (
                                                                                            j === 0 ? <p className="pt-2" key={j}>{name}</p> : <p key={j} className="text-sm">{name}</p>
                                                                                        ))
                                                                                    ))
                                                                                ) : (
                                                                                    <>-</>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div className="w-40">
                                                                            <p className="bg-gray-100 dark:bg-gray-700">Composers</p>
                                                                            {
                                                                                song.composers ? (
                                                                                    song.composers.map(composer => (
                                                                                        composer.names.map((name, j) => (
                                                                                            j === 0 ? <p className="pt-2" key={j}>{name}</p> : <p key={j} className="text-sm">{name}</p>
                                                                                        ))
                                                                                    ))
                                                                                ) : (
                                                                                    <>-</>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div className="w-40">
                                                                            <p className="bg-gray-100 dark:bg-gray-700">Arrangers</p>
                                                                            {
                                                                                song.arrangers ? (
                                                                                    song.arrangers.map(arranger => (
                                                                                        arranger.names.map((name, j) => (
                                                                                            j === 0 ? <p className="pt-2" key={j}>{name}</p> : <p key={j} className="text-sm">{name}</p>
                                                                                        ))
                                                                                    ))
                                                                                ) : (
                                                                                    <>-</>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    >
                                                        <Link className="font-medium text-cyan-600 hover:underline dark:text-cyan-500">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                                            </svg>
                                                        </Link>
                                                    </Popover>
                                                </Table.Cell>
                                                <Table.Cell className="text-center">
                                                    <a href={`https://naedist.animemusicquiz.com/${song.audio}`} target="_blank" className="font-medium text-cyan-600 hover:underline dark:text-cyan-500" rel="noreferrer">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                                        </svg>
                                                    </a>
                                                </Table.Cell>
                                                <Table.Cell className="text-center">
                                                    <Link onClick={(e) => { e.preventDefault(); setCurrentSongId(song.annSongId) }} className="font-medium text-cyan-600 hover:underline dark:text-cyan-500">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
                                                        </svg>
                                                    </Link>
                                                </Table.Cell>
                                            </Table.Row>
                                        ))
                                    }
                                </Table.Body>
                            </Table>
                        ))
                    ) : (
                        <div>
                            Not Found
                        </div>
                    )
                }
            </div>
        </>
    );
}
