import { Card } from "flowbite-react";
import { useEffect, useState } from "react";
import AdvancedParamsComponent from "./AdvancedParamsComponent";
import AnimeListWithParamsComponent from "./AnimeListWithParamsComponent";
import { customCardTheme } from "../templates/customThemes";

const defaultAdvancedParams =
{
    "animeStatusPTW": false,
    "animeStatusWatching": true,
    "animeStatusCompleted": true,
    "animeStatusOnHold": false,
    "animeStatusDropped": false,
    "songTypeOP": true,
    "songTypeED": true,
    "songTypeInsert": true,
    "songDifficultyAdvanced": false,
    "songDifficultyEasy": true,
    "songDifficultyMedium": true,
    "songDifficultyHard": true,
    "songDifficultyMin": 0,
    "songDifficultyMax": 100,
    "animeTypeTV": true,
    "animeTypeMovie": true,
    "animeTypeOVA": true,
    "animeTypeONA": true,
    "animeTypeSpecial": true,
    "songCategoryStandard": true,
    "songCategoryInstrumental": true,
    "songCategoryChanting": true,
    "songCategoryCharacter": true,
    "otherRebroadcast": 2,
    "otherDub": 2,
}

export default function AnimeListComponent({ setCurrentSongId, animeList, GenPlaylist }) {
    const [advancedParams, setAdvancedParams] = useState(defaultAdvancedParams);

    useEffect(() => { }, [advancedParams]);

    return (
        <div className="grid grid-cols-10 gap-2">
            <div className="col-span-10 md:col-span-8 order-2 md:order-1">
                <Card theme={customCardTheme}>
                    <AnimeListWithParamsComponent setCurrentSongId={setCurrentSongId} advancedParams={advancedParams} animeList={animeList} GenPlaylist={GenPlaylist} />
                </Card>
            </div>
            <div className="col-span-10 md:col-span-2 order-1 md:order-2">
                <Card theme={customCardTheme} className="p-3">
                    <AdvancedParamsComponent setAdvancedParams={setAdvancedParams} />
                </Card>
            </div>
        </div>
    );
}
