import { Breadcrumb, DarkThemeToggle, Flowbite, Footer, Navbar } from "flowbite-react";
import { Link } from "react-router-dom";
import { customFooterTheme, customNavbarTheme } from "./customThemes";

export default function DefaultLayout({ header, children, playerMargin }) {
    return (
        <Flowbite>
            <div className="dark:bg-gray-800 min-h-screen">
                <Navbar theme={customNavbarTheme}>
                    <Navbar.Brand href={'/'}>
                        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
                            OtakuDB.Songs
                        </span>
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse>
                        {/* <Navbar.Link as={Link} href="#">
                            Search
                        </Navbar.Link>
                        <Navbar.Link href="#">
                            My Songs
                        </Navbar.Link> */}
                        <Navbar.Link href="#">
                            <DarkThemeToggle className="rounded-none hover:text-gray-900 dark:hover:bg-none text-gray-700 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:hover:bg-transparent md:hover:text-cyan-700 md:dark:hover:bg-transparent md:dark:hover:text-white" />
                        </Navbar.Link>
                    </Navbar.Collapse>
                </Navbar>
                {
                    header && (
                        <Breadcrumb className="bg-gray-50 px-2 py-2 sm:px-4 dark:bg-gray-800">
                            <Breadcrumb.Item className="container mx-auto">
                                {header}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    )
                }
                <main className="dark:bg-gray-700 px-2 py-2 sm:px-4">
                    <div className="container mx-auto">
                        {children}
                    </div>
                </main>

                <Footer container theme={customFooterTheme}>
                    <div className="container mx-auto">
                        <div className="justify-between sm:flex sm:items-center sm:justify-between">
                            <Footer.Copyright href={'/'} by="OtakuDB.Songs v0.3.7" year={2024} />
                            <Footer.LinkGroup>
                                <Footer.Link href="#">Anisong</Footer.Link>
                                <Footer.Link href="#">MyAnimeList</Footer.Link>
                                <Footer.Link href="#">Anilist</Footer.Link>
                            </Footer.LinkGroup>
                        </div>
                    </div>
                </Footer>
                {playerMargin && (
                    <div className="h-16"></div>
                )}
            </div>
        </Flowbite>
    );
}